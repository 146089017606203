<template>
  <div class="page-content">
    <breadcrumb title="Dein Profil" :subtitle="userName" bgImage="/assets/bg/profile.jpg" :marginMdLeft="true">
      <button
      @click="$router.push('/edit-profile')"
      class="btn btn-simple btn-icon btn-setting float-right">
      <span class="d-none d-sm-block">Einstellungen</span>
      <span class="material-icons">settings</span>
    </button>
  </breadcrumb>

  <div class="container-fluid">
    <div v-for="(listType,index) in collections" :key="index">
      <div v-if="hasCollections(listType) && index!='apps'">
        <card-slider :title="getCollectionTypeTitle(index)" :data="getCollections(index,listType)" card="reiseplan" />
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <div class="no-collections" v-if="!hasAnyCollections">Reise, so wie du bist. <br> Erstelle dir jetzt deinen Reiseplan. </div>
        <button class="btn btn-active btn-home__reise create-collection-btn" @click="showModal">Reiseplan erstellen</button>
      </div>
    </div>

  </div>
  <div class="footer-margin"></div>
</div>
</div>
</template>

<script>
  import { bus } from '@/main';
  import { getCollectionsOfUser } from '@/api/collection';
  import { showLoader, hideLoader } from '@/utils/helpers';

  export default {

    name: 'Profile',
    components: {
      Breadcrumb: () => import('@/components/layout/Breadcrumb'),
      CardSlider: () => import('@/components/CardSlider'),
      CreateCollectionModal: () => import('@/components/modal/createCollection.vue'),
    },
    computed:{
      user(){
        return this.$store.state.user;
      },
      userName(){
        return this.$store.state.user.name;
      },
      collections(){
        return this.$store.getters.getCollections;
      },
      hasAnyCollections(){
        if(this.collections!=null){
          for (var prop in this.collections) {
            if (Object.prototype.hasOwnProperty.call(this.collections, prop)) {
              if(this.hasCollections(this.collections[prop])){
                return true;
              }
            }
          }

        }
        return false;
      }
    },
    mounted(){
      this.loadCollections();
    },
    methods: {
      showLoader,
      hideLoader,
      getCollectionsOfUser,
      showModal () {
        bus.$emit('show-create-plan-modal', true)
      },
      closeCollectionDialogAndReloadCollection(){
        this.showAddNewCollection=false;
        this.loadCollections();
      },
      hasCollections(listType){
        if(listType.length>0){
          return true;
        }
        return false;
      },
      getCollectionTypeTitle(listType){
        var title = "";
        switch(listType){
          case "shared":
          title = "Mit mir geteilte Reisepläne";
          break;
          case "own":
          title = "Eigene Reisepläne";
          break;
          case "institution":
          title = "Reisepläne meiner Institution";
          break;
          case "apps":
          title = "Reisepläne dieser App";
          break;
        }
        return title
      },
      loadCollections(){
        this.loader = this.showLoader(this.loader);
        this.$store.dispatch('loadUserCollections').then(() => {
        }).catch(error => {
          this.$notify({
            duration: 4500,
            type: 'error',
            title: 'Fehler bei der Anmeldung!',
            text: error
          });
        }).finally(()=>{
          this.loader = this.hideLoader(this.loader);
        });
      },
      getCollections(listType,listArray){
        if(listType!="apps"){
          return listArray;
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
  .page-content{
    min-height: 100vh;
    margin-bottom: 10vh;

    @media (max-width: 991px) {
      margin-bottom: 0px;
    }
  }

  .no-collections{
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .create-collection-btn{
    margin-bottom: 30px;
  }
</style>